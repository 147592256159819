import { createContext, useEffect, useState, useContext } from 'react';
import { supabaseClient } from '../utils/client';
import {
  FIGMA_USERS_TABLE,
  ATLASSIAN_USERS_TABLE,
  SLACK_USERS_TABLE,
  GMAIL_USERS_TABLE
} from "../config";

const UserContext = createContext({});

const UserProvider = ({ children }) => {
  const [user, setUser] = useState({ loading: true });
  const [authInfo, setAuthInfo] = useState({ loading: true });

  useEffect(() => {
    (async () => {
      const { data: { session }, error} = await supabaseClient.auth.getSession();
      // const { data: { user }, error } = await supabaseClient.auth.getUser();
      // console.log("In useEffect of user.js", session);
      if (error) {
        console.error(error);
        return;
      }
      setUser({ ...session?.user, loading: false });
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (user.loading) return;

      let _authInfo = {};
      if (user.id) {
        const tables = [
          FIGMA_USERS_TABLE,
          ATLASSIAN_USERS_TABLE,
          SLACK_USERS_TABLE,
          GMAIL_USERS_TABLE
        ];
        for (const tableName of tables) {
          const service_user = await supabaseClient
            .from(tableName)
            .select("*")
            .eq("supabase_user_id", user.id);

          _authInfo[tableName] = { ...service_user?.data[0] };
          _authInfo[tableName].isConnected = !!service_user.data?.[0]?.access_token;
        }
      }
      setAuthInfo({ ..._authInfo, loading: false });
    })();
  }, [user.loading]);

  return (
    <UserContext.Provider value={{ user, authInfo, setAuthInfo }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);

export default UserProvider;
